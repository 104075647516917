// import external modules
import React, { Suspense, lazy } from "react";
import ReactDOM from "react-dom";
import { Provider } from "react-redux";
import { PersistGate } from 'redux-persist/integration/react'

// import internal(own) modules
import registerServiceWorker from "./registerServiceWorker";
import { store, persistor } from "./redux/storeConfig/store";
import ReduxToastr from 'react-redux-toastr'

import "font-awesome/css/font-awesome.min.css";

import "./index.scss";
/*import 'bootstrap/dist/css/bootstrap.min.css';*/
import Spinner from "./components/spinner/spinner";

const LazyApp = lazy(() => import("./app/app"));

ReactDOM.render(
   <Provider store={store}>

         <Suspense fallback={<Spinner />}>
      <PersistGate loading={null} persistor={persistor}>
         <LazyApp />
      </PersistGate>
         <ReduxToastr
               timeOut={4000}
               newestOnTop={false}
               preventDuplicates
               position="top-left"
               transitionIn="fadeIn"
               transitionOut="fadeOut"
               progressBar
               closeOnToastrClick/>
         </Suspense>
   </Provider>,
   document.getElementById("root")
);
registerServiceWorker();
